import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import CallToActions from '../components/CallToActions';
import ModularBlocks from '../components/ModularBlocks';

const IndexPage = () => {
  const {
    datoCmsHome: {
      seoMetaTags,
      title,
      banners,
      ctasHeading,
      ctas,
      modularBlocks,
    },
  } = useStaticQuery(graphql`
    query IndexPageQuery {
      datoCmsHome {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        banners {
          image {
            ...BannerDesktopImageFragment
          }
          mobileImage {
            ...BannerMobileImageFragment
          }
          link {
            text
            page {
              ...LinkFragment
            }
          }
        }
        ctasHeading
        ctas {
          ... on DatoCmsPage {
            title
            featuredImage {
              ...CallToActionsItemImageFragment
            }
            ...LinkFragment
          }
          ... on DatoCmsProductCategory {
            title
            featuredImage {
              ...CallToActionsItemImageFragment
            }
            ...LinkFragment
          }
        }
        modularBlocks {
          ...ContentModularBlockFragment
          ...IconContentBlocksModularBlockFragment
          ...LinkCardsModularBlockFragment
          ...TestimonialsModularBlockFragment
        }
      }
    }
  `);

  return (
    <Layout seo={seoMetaTags}>
      <main>
        <Banner heading={title} items={banners} />
        <CallToActions heading={ctasHeading} items={ctas} />
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export default IndexPage;
