import React from 'react';
import { navigate, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  standardTransition,
  visuallyHidden,
} from '../styles';
import { Container, Button, Link } from './ui';
import { buildUrl } from '../utils';

const StyledCallToActions = styled.section`
  ${sectionMargins()};
`;

const StyledHiddenHeading = styled.h2`
  ${visuallyHidden()};
`;

const StyledItems = styled.div`
  display: grid;
  gap: 15px;

  ${minBreakpointQuery.xtiny`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.tiny`
    gap: 20px;
  `}

  ${minBreakpointQuery.small`
    row-gap: 25px;
  `}

  ${minBreakpointQuery.medium`
    grid-template-columns: repeat(3, 1fr);
    row-gap: 30px;
  `}

  ${minBreakpointQuery.large`
    row-gap: 35px;
  `}
`;

// moved up for hover
const StyledButtonWrapper = styled.div`
  ${maxBreakpointQuery.mlarge`
    ${visuallyHidden()};
  `}

  ${minBreakpointQuery.mlarge`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    opacity: 0;
    transition: ${standardTransition('opacity')};
  `}

  &:before {
    ${minBreakpointQuery.mlarge`
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: ${brandColours.quinary};
      opacity: 0.5;
    `}
  }
`;

const StyledItem = styled.article`
  display: flex;
  flex-direction: column;
  background-color: ${brandColours.secondary};
  cursor: pointer;
  transition: ${standardTransition('background-color')};

  ${minBreakpointQuery.xtiny`
    &:nth-child(3n) {
      grid-column: 1 / 3;
    }
  `}

  ${minBreakpointQuery.tsmall`
    &:nth-child(3n) {
      grid-column: auto;
    }
  `}

  &:hover {
    background-color: ${brandColours.primary};

    ${StyledButtonWrapper} {
      ${minBreakpointQuery.mlarge`
        opacity: 1;
      `}
    }
  }
`;

const StyledImageWrapper = styled.div`
  ${minBreakpointQuery.mlarge`
    position: relative;
  `}
`;

const StyledButton = styled(Button)`
  ${minBreakpointQuery.mlarge`
    position: relative;
  `}
`;

const StyledHeading = styled.h3`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  height: 100%;
  color: ${standardColours.black};
  font-weight: ${fontWeights.regular};
  ${fontSize(16)};
  text-align: center;

  ${minBreakpointQuery.small`
    padding: 15px 30px;
    ${fontSize(20)};
  `}

  ${minBreakpointQuery.large`
    padding: 25px 35px;
    ${fontSize(24)};
  `}
`;

const CallToActions = ({ heading, items }) => (
  <StyledCallToActions>
    <Container>
      <StyledHiddenHeading>{heading}</StyledHiddenHeading>
      <StyledItems>
        {items.map(item => {
          const { title, slug, featuredImage } = item;

          return (
            <StyledItem onClick={() => navigate(buildUrl(slug, item))}>
              <StyledImageWrapper>
                <GatsbyImage
                  image={featuredImage.gatsbyImageData}
                  alt={featuredImage.alt}
                />
                <StyledButtonWrapper>
                  <StyledButton to={item}>See Range</StyledButton>
                </StyledButtonWrapper>
              </StyledImageWrapper>
              <StyledHeading>
                <Link to={item}>{title}</Link>
              </StyledHeading>
            </StyledItem>
          );
        })}
      </StyledItems>
    </Container>
  </StyledCallToActions>
);

export default CallToActions;

export const CallToActionsFragment = graphql`
  fragment CallToActionsItemImageFragment on DatoCmsFileField {
    gatsbyImageData(width: 480, height: 300)
    alt
  }
`;
