import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { maxBreakpointQuery, visuallyHidden } from '../styles';
import { Container, Link, Slider } from './ui';

const StyledBanner = styled.section``;

const StyledContainer = styled(Container)`
  ${maxBreakpointQuery.mlarge`
    padding-right: 0;
    padding-left: 0;
  `}
`;

const StyledHeading = styled.h1`
  ${visuallyHidden()};
`;

const StyledItems = styled(Slider)`
  .slick-arrow {
    &.slick-prev {
      left: 50px;
    }

    &.slick-next {
      right: 50px;
    }
  }
`;

const StyledItem = styled.div`
  position: relative;
`;

const StyledImage = styled(GatsbyImage)`
  ${maxBreakpointQuery.smedium`
    aspect-ratio: 1/1;
  `}
`;

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  span {
    ${visuallyHidden()};
  }
`;

const sliderOptions = {
  arrows: true,
  autoplay: true,
  dots: false,
  adaptiveHeight: true,
};

const Banner = ({ heading, items }) => (
  <StyledBanner>
    <StyledContainer>
      <StyledHeading>{heading}</StyledHeading>
      <StyledItems {...sliderOptions}>
        {items.map(({ image, mobileImage, link }, i) => {
          const desktop = getImage(image);
          const mobile = getImage(mobileImage);
          const images = withArtDirection(
            desktop,
            mobile
              ? [
                  {
                    media: '(max-width: 767px)',
                    image: mobile,
                  },
                ]
              : [],
          );

          return (
            <StyledItem key={i}>
              <StyledImage
                image={images}
                alt={image.alt}
                loading={i === 0 && 'eager'}
                fetchpriority={i === 0 && 'high'}
              />
              <StyledLink to={link.page}>
                <span>{link.text}</span>
              </StyledLink>
            </StyledItem>
          );
        })}
      </StyledItems>
    </StyledContainer>
  </StyledBanner>
);

export default Banner;

export const BannerImageFragments = graphql`
  fragment BannerMobileImageFragment on DatoCmsFileField {
    gatsbyImageData(width: 768, height: 768)
    alt
  }

  fragment BannerDesktopImageFragment on DatoCmsFileField {
    gatsbyImageData(width: 1440, height: 700)
    alt
  }
`;
